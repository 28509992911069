import React, { useContext, useEffect } from 'react'
import './index.css'
import CardEtapas from '../../Components/CardEtapas'
import { InputText } from 'primereact/inputtext'
import useForm from '../../hooks/useForm'
import IMGEsqueceuSenha from '../../Assets/Image/3.png'
import { ButtonLabel, ButtonLabelComponent, InputTextEmail, InputTextPassword } from '../../Components/Button'
import { isStrongPassword } from '../../Utils/Shared/Validators/PasswordValidators'
import { useNavigate } from 'react-router-dom'
import { showToast } from '../../Utils/Shared/CustomToast'
import { useSecurityServices } from '../../Services/Security/SecurityServices'
import { SecurityContext } from '../../Contexts/Security/SecurityContext'
// import Input from '@mui/material/Input'


const EsqueceuSenha = () => {    
    const [form, handleInputChange] = useForm({ 
        email: '', 
        codigo: '',
        novaSenha: '',
        confirmarSenha: ''
    }) 

    const securityServices = useSecurityServices()
    const { stepEtapa, setStepEtapa } = useContext(SecurityContext)
    
    const navegate = useNavigate()
    let headerTitulo = 'ESQUECI A SENHA'

    useEffect(()=>{
        setStepEtapa(1)
    },[])

    const onClickEtapa = async (step) => {
        
        try {
           
            if(step === 2){
                if(form.email === '' || form.email === undefined) return showToast('warning','E-Mail não informado!')
    
                const isUsuarioExiste = await securityServices._validarUsuarioExiste(form.email)
                
                if(isUsuarioExiste){
                    const response = await securityServices._enviarCodigoSeguranca(form.email, 'esqueceuSenha')
                    if(response?.status === 200){
                        setStepEtapa(step)
                    }
                    
                    return
                }
    
                if(!isUsuarioExiste){
                    showToast('warning','E-mail não encontrado em nosso sistema!')
                    return
                }
                
            } 
    
            if(step === 3){
                if(form.codigo === 0 || form.codigo === undefined || form.codigo === '') return showToast('warning','Código não informado!')
                const retorno = await securityServices._validarCodigoSeguranca(form.email, Number(form.codigo))

                if(retorno?.status === 200){                
                    setStepEtapa(step)
                }

                if(retorno.status !== 200){
                    const changeInputCode={
                        target:{
                            value: '', 
                            name: 'codigo'
                        }                        
                    }

                    handleInputChange(changeInputCode)
                }

                return
            }  
            
            if(step === 4){
                if(form.codigo === '' || form.codigo === 0 || form.codigo === undefined) return showToast('warning','Código não informado!')
                setStepEtapa(step)
            }
    
            if(step === 5){
                if(form.novaSenha === '' || form.novaSenha === undefined) return showToast('warning','Senha não informada!') 
                if(form.confirmarSenha === '' || form.confirmarSenha === undefined) return showToast('warning','Nova Senha não informada!')
                if(form.novaSenha !== form.confirmarSenha) return showToast('warning','Senha não confere!')
    
                if(!isStrongPassword(form.novaSenha)){
                    return showToast('warning',<div className='toat-warning-senha'>
                       <span>1. Contém pelo menos uma letra maiúscula (A-Z)</span> <hr></hr>
                       <span>2. Contém pelo menos uma letra minúscula (a-z)</span> <hr></hr>
                       <span>3. Contém pelo menos um caractere numérico (0-9)</span> <hr></hr>
                       <span>{"4. Contém pelo menos um caractere especial '(!@#$%^&*()_+[]{};:|,.<>?)"}</span> <hr></hr>
                       <span>5. Tem pelo menos 8 caracteres</span> 
                    </div>)
                }
    
                if(isStrongPassword){
                    const response = await securityServices._alterarSenha(form.novaSenha)
                    if(response.statusCode !== 400 ){
                        navegate('/')
                    }
                }
            }
    
            if(step === 6){
                const response =  await securityServices._enviarCodigoSeguranca(form.email, 'esqueceuSenha')
                return response
            }

        } catch (error) {
            console.log(error)
        }
    }

    const onClickIconStep = (e) => {
        switch(e.currentTarget.id){
            case 'step1':
                setStepEtapa(1)
                break
            case 'step2':
                if(form.email === '') return showToast('warning','E-Mail não informado!')
                setStepEtapa(2)
                break
            case 'step3':
                if(form.email === '') return showToast('warning','Código não informado!')
                setStepEtapa(3)
                break
            case 'step4':
                setStepEtapa(4)
                break              
            default:
                setStepEtapa(1)
                break                                      
        }
    }

    return(<div className='main-container-cadastro-pessoa-juridica'>
        {stepEtapa === 1 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} totalStepEtapas={3} navegateEtapa={(e) => onClickIconStep(e)} imagem={IMGEsqueceuSenha}>       

                <div className='conteudo-etapa'>
                    <span className='pessoa-juridica-subheading'>Para começarmos, preencha seu e-mail abaixo:</span>
                    <InputTextEmail name='email' onChange={handleInputChange} value={form.email} />
                </div>

                <div className='card-etapa-botoes'>
                    <ButtonLabel onClick={()=> window.history.back()} width={'180px'}>Voltar</ButtonLabel>
                    <ButtonLabel onClick={()=> onClickEtapa(2)} width={'180px'}>Próximo</ButtonLabel>
                </div>
            </CardEtapas>
        }

        {stepEtapa === 2 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} totalStepEtapas={3} navegateEtapa={(e) => onClickIconStep(e)} imagem={IMGEsqueceuSenha}>
                <div className='conteudo-etapa'>
                    <h1 className='pessoa-juridica-header'><b>Digite o código de segurança</b></h1>
                    <span className='pessoa-juridica-subheading' >Digite o código de segurança que foi enviado ao seu 
                    {<br></br>}e-mail. 
                    {<br></br>}Enviamos para {form.email? form.email : ''}</span>
                    <InputTextPassword name={'codigo'} value={form.codigo} onChange={handleInputChange} />  
                    <span className='informe-codigo'>Informe o código acima</span>                  
                </div>

                <div className='card-etapa-botoes'>
                    <ButtonLabel onClick={()=> onClickEtapa(6)} width={'180px'}>Reenviar e-mail</ButtonLabel>
                    <ButtonLabel onClick={()=> onClickEtapa(3)} width={'180px'}>Próximo</ButtonLabel>
                </div>
            </CardEtapas>
        }


        {stepEtapa === 3 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} totalStepEtapas={3} navegateEtapa={(e) => onClickIconStep(e)} imagem={IMGEsqueceuSenha}>
                <span  className='pessoa-juridica-header'><b>Cadastro da senha!</b></span>

                <div className='info-senha'>
                    <span>A senha deve ser de no mínimo 8 dígitos e conter pelo menos uma letra MAIÚSCULA, </span>
                    <span>uma minúscula, um caráter especial (@!*) e um número.</span>
                </div>
                
                <div className='conteudo-etapa'>
                    <InputTextPassword name={'novaSenha'} value={form.codigo} placeholder='Nova senha' onChange={handleInputChange} /> 
                    <InputTextPassword name={'confirmarSenha'} value={form.codigo} placeholder='Confirmar Senha' onChange={handleInputChange} /> 
                </div>

                <div className='card-etapa-botoes'>
                    <ButtonLabelComponent position={2} label='Entrar' onClick={()=> onClickEtapa(5)}/>
                    <ButtonLabel onClick={()=> onClickEtapa(5)} width={'180px'}>Entrar</ButtonLabel>
                </div>
            </CardEtapas>
        }
        
    </div>)
}

export default EsqueceuSenha