import { useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { showToast } from '../../Utils/Shared/CustomToast';
import { SecurityContext } from '../../Contexts/Security/SecurityContext';


export const useSecurityServices = () => {
    const { enviarCodigoSeguranca, validarCodigoSeguranca, alterarSenha, validarUsuarioExiste } = useContext(SecurityContext);

    const _enviarCodigoSeguranca = async ( email, event ) => {
        try{
            const response = await enviarCodigoSeguranca(email, event)
            
            if(response?.status === 200){
                showToast('success', 'O código de segurança foi enviado para o seu e-mail. Por favor, valide-o.')
            }
            
            
            if(response?.status !== 200){
                showToast('warning', response.data.message)
            } 

            return response
        } catch (error) {
            showToast('warning', 'Não foi possível enviar o código de segurança!')
        }
    }

    const _validarCodigoSeguranca = async ( email, codigoSeguranca ) => {
        try{
            const response = await validarCodigoSeguranca(email, codigoSeguranca)
            if(response?.status === 200){
                localStorage.setItem('portal-adv-token', response.data.token)
                showToast('success', 'Código validado com sucesso.')                
            }
            
            if(response?.status !== 200){
                showToast('warning', response.data.message)                          
            } 

            return response
        } catch (error) {
            showToast('warning', error.message)
            return error
        }
    }

    const _alterarSenha = async ( novaSenha ) => {
        try{
            const token = localStorage.getItem('portal-adv-token')
            const response = await alterarSenha(novaSenha, token )

            if(response.status === 200){
                showToast('success', response.data.message)
                localStorage.clear()
            }
            return response            
        } catch (error) {
            showToast('warning', error.message)
            return error
        }
    }

    const _validarUsuarioExiste = async ( email ) => {
        try{
            const response = await validarUsuarioExiste(email)

            if(response?.status === 200 && response.data.message === true ) return true
            
            if(response?.status !== 200) return false

        } catch (error) {
            showToast('warning', 'Usuário não existe!')
        }
    }
    
    return { 
        _enviarCodigoSeguranca,
        _validarCodigoSeguranca, 
        _alterarSenha, 
        _validarUsuarioExiste 
    }
}
