import React from 'react';
import { CardModulo } from '../../Components/CardModulo';
import IconModuloPaineisGestao from '../../Assets/Modulos/IconModuloPaineisGestao.png';
import IconModuloDepositosJudiciais from '../../Assets/Modulos/IconModuloDepositosJudiciais.png';
import IconModuloSeguros from '../../Assets/Modulos/IconModuloSeguros.png';
import IconSair from '../../Assets/Logins/IconSair.png';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { useAuthServices } from '../../Services/Auth/SignInServices';
import { useSergurosServices } from '../../Services/Seguros/SegurosServices';
import Storage from '../../Utils/Shared/LocalStorage/Storage';
import { usePainelGestaoServices } from '../../Services/PainelGestao/PainelGestaoServices';

const Modulos = () => {
    const user = Storage.getLocalStorageUser()
    const authServices = useAuthServices();
    const sergurosServices = useSergurosServices();
    const painelGestaoServices = usePainelGestaoServices()
    const navigate = useNavigate();
    
    const sair = async () => {
        const authSignIout = await authServices.authSignOutServices();
        if (authSignIout) {
            navigate('');
        }
    }

    const handleSubmit = async () => {        
        await painelGestaoServices.portalPaineisGestao(user.email)
    };

    
    const navegateModulo = async (nomeModulo) => {
        switch(nomeModulo){
            case 'seguros':
                sergurosServices.portalSeguros();
                break
            case 'gestao':
                await handleSubmit()
                break
            case 'depositoJudiciais':
                navigate('')
                break
            default:
                navigate('')
        }
    }

    return (
        <div className='main-container-modulos'>
            <div className="modulos-header">
                <span>Olá, {user.nome}</span>
            </div>
            <div className="modulos-body">
                <CardModulo cardIcon={IconModuloPaineisGestao} cardLabel={'Painéis de Gestão'} onClick={() => navegateModulo('gestao')} />
                <CardModulo cardIcon={IconModuloDepositosJudiciais} cardLabel={'Depósito Judiciais'} isDisabled={true}/>
                <CardModulo cardIcon={IconModuloSeguros} cardLabel={'Seguros'} onClick={() => navegateModulo('seguros')} />
            </div>
            <div className="modulos-footer">
                <img src={IconSair} alt="" onClick={sair} />
            </div>
        </div>
    );
}

export default Modulos;
