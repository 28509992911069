import React from 'react';
import './index.css';

export const CardModulo = ( { cardIcon, cardLabel, onClick, isDisabled } ) => {
    return(
        <div className="card-container-modulos">
            <img src={cardIcon} alt="" className="card-modulo-icon" />
            <span className="card-modulo-label">{cardLabel}</span>
            <button className="card-modulo-acessar" onClick={onClick} disabled={isDisabled}>
                {isDisabled ? 'Em breve' : 'Acessar'}
            </button>
        </div>
    );
}
